import React, { Component } from "react";
import { Fade, Slide } from "react-reveal";

class Contact extends Component {
  render() {
    if (!this.props.data) return null;

    const name    = this.props.data.name;
    const city    = this.props.data.address.city;
    const state   = this.props.data.address.state;
    const phone   = this.props.data.phone;
    const message = this.props.data.contactmessage;
    const email   = this.props.data.email;

    return (
      <section id="contact">
        <Fade bottom duration={1000}>
          <div className="row">
            <div className="eight columns">
              <h1><br />{message}</h1>

              <aside className="four columns footer-widgets">
                <div className="widget widget_contact">
                  <p className="address">
                    {name}
                    <br />
                    {city}, {state}
                    <br />
                    <span>{phone}</span>
                    <br />
                    <span>{email}</span>
                  </p>
                </div>
              </aside>

            </div>
          </div>
        </Fade>

        <div className="row">
          <Slide left duration={1000}>
            <div className="four columns">
              <form action="mailto:info@ufo-co.com?subject=UFO-Inquiry" id="contactForm" name="contactForm">
                <fieldset>
                  <div>
                    <button className="submit"> Email UFO</button>
                  </div>
                </fieldset>
              </form>

              <div id="message-warning"> Error sending your message.</div>
              <div id="message-success">
                <i className="fa fa-check"></i>Your message was sent, thank you!
                <br />
              </div>
            </div>
          </Slide>
        </div>
      </section>
    );
  }
}

export default Contact;
